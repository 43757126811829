.blog-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #A49E9E;
    text-decoration: none;
    color: #2E2424;
    border-radius: 8px;
    width: 80%;
    align-items: center;
    margin: 2rem auto;
}

.blog-link-image {
    padding: 1rem 2rem;
    width: 65%;
}

.blog-link-intro {
    display: flex;
    flex-direction: column;
}

.blog-links h1 {
    font-family: 'IM Fell English SC', serif;
    font-size: 18px;
    margin-bottom: 0;
    padding-right: 1rem;
}

.blog-date {
    justify-self: end;
    font-size: 17px;
    font-family: 'Italianno', cursive;
    align-self: flex-end;
    padding-right: 2rem;
    color: white;
}

.links-intro-txt {
    grid-column: 1 / -1;
    padding: 0 2rem 1rem 2rem;
}

.read-more {
    font-weight: bold;
}

@media only screen and (min-width: 370px) {
    .blog-links h1 {
        font-size: 22px;
    }

    .blog-link-intro {
        padding-bottom: 3rem;
    }
}

@media only screen and (min-width: 600px) {
    .blog-link-image {
        width: 180px;
    }

    .blog-link-intro {
        padding-bottom: 5rem;
    }

    .blog-links {
        width: 520px;
    }

    .blog-links h1 {
        font-size: 28px;
    }
}