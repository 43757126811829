.txt-2 {
    display: flex;
    flex-direction: column;
}

.txt-2 img {
    width: 80%;
    max-width: 350px;
    align-self: center;
    padding: 3rem 0;
}

.bl2-img-ctn {
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.bl2-img-ctn img {
    max-width: 300px;
}

@media only screen and (max-width: 868px) {
    .bl2-img-ctn img {
            width: 45%;
        }
}
