.cv-title {
    display: flex;
    justify-content: center;
    padding: 3rem 0 2rem 0
}

.cv-list {
    padding: 1rem 4rem;
}

.cv-list li {
    padding: 1rem;
}

@media only screen and (max-width: 420px) {

    .cv-list {
        padding: 1rem 2rem;
    }
}