.blog_1-img_1-description,
.blog_1-img_2-description,
.blog_1-img_3-description,
.blog_1-img_4-description {
    font-size: 13px;
    width: 80%;
    max-width: 330px;
}

.blog_1-img-descr-ctn {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
}

.blog_1-img_3-description,
.blog_1-img_4-description {
    max-width: 500px;
}

.blog_1-img_1,
.blog_1-img_3 {
    width: 80%;
    max-width: 300px;
}

.blog_1-img_2 {
    width: 80%;
    max-width: 500px;
}

.blog_1-img_4 {
    width: 80%;
    max-width: 600px;
}

@media only screen and (min-width: 768px) {
    .blog_1-ctn {
            width: 70%;
        }
}
