.navbar {
    display: grid;
    grid-template-columns: 1fr 10fr;
    justify-items: end;
    justify-content: left;
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: #151414;
    height: 60px;
    margin-top: -3px;
}

.nav-list.open {
    background-color: rgb(21, 20, 20);
    display: grid;
    border-radius: 2%;
    transition: max-height 1.5s;
    max-height: 27rem;
}

.nav-list.closed {
    background-color: rgb(21, 20, 20);
    display: grid;
    border-radius: 2%;
    transition: max-height 0.5s;
    max-height: 0;
}

.nav-list a {
    text-decoration: none;
    color: white;
    border-bottom-style: solid;
    margin: 0 1rem;
}

.nav-list a:hover {
    background-color: rgb(35, 33, 33);
}

.nav-list a:active {
    background-color: rgb(65, 64, 64);
}

.menu-icon {
    color: white;
    font-size: 2rem;
}

.menu-button {
    background-color: rgb(21, 20, 20);
    border: none;
    padding: 1rem 1rem 0 1rem;
}

.menu-button::after {
    content: 'Menu';
    color: white;
    font-size: large;
    position: relative;
    bottom: 7px;
    left: 3px;
    font-family: var(--main-font)
}

.menu-button:hover {
    cursor: pointer;
}

.banner {
    display: block;
    width: 100%;
    height: auto;
}

.nav-list.closed a.menu-item {
    padding: 0.5rem 1rem 1rem 1rem;
}

.nav-list.open a.menu-item {
    padding: 0.5rem 1rem 1rem 1rem;
}

.nav-list.open a.menu-item.first {
    position: relative;
    right: 0;
    transition: right 0.2s;
}

.nav-list.closed a.menu-item.first {
    position: relative;
    right: 9rem;
    transition: right 0.8s;
}

.nav-list.open a.menu-item.second {
    position: relative;
    right: 0;
    transition: right 0.4s;
}

.nav-list.closed a.menu-item.second {
    position: relative;
    right: 9rem;
    transition: right 0.6s;
}

.nav-list.open a.menu-item.third {
    position: relative;
    right: 0;
    transition: right 0.6s;
}

.nav-list.closed a.menu-item.third {
    position: relative;
    right: 9rem;
    transition: right 0.5s;
}

.nav-list.open a.menu-item.fourth {
    position: relative;
    right: 0;
    transition: right 0.8s;
}

.nav-list.closed a.menu-item.fourth {
    position: relative;
    right: 9rem;
    transition: right 0.3s;
}

.nav-list.open a.menu-item.fifth {
    position: relative;
    right: 0;
    transition: right 1s;
}

.nav-list.closed a.menu-item.fifth {
    position: relative;
    right: 9rem;
    transition: right 0.15s;
}

.nav-list.open a.menu-item.sixth {
    position: relative;
    right: 0;
    transition: right 1.2s;
    border-bottom: none;
}

.nav-list.closed a.menu-item.sixth {
    position: relative;
    right: 9rem;
    transition: right 0.1s;
}

.signature {
    color: white;
    width: max-content;
    font-family: 'Great Vibes', cursive;
    padding: 1rem 2rem 0 0;
    font-size: x-large;
}

.signature-link {
    text-decoration: none;
    color: white;
}

@media only screen and (min-width: 900px) {
    .banner {
        height: 391px;
        margin-top: 57px;
    }
}

@media only screen and (min-width: 850px) {
    .nav-list.bigger-width {
        display: block;
        padding-left: 1rem;
    }

    .menu-icon,
    .menu-button {
        display: none;
    }

    #big-width-links {
        position: inherit;
        padding: 0.5rem;
        border: solid 1px rgba(255, 255, 255, 0);
        border-radius: 5px;
        transition: border 1s;
    }

    #big-width-links:hover {
        border: solid 1px rgb(255, 255, 255);
        border-radius: 10px;
    }

    #big-width-links:focus {
        border: solid 1px rgb(255, 255, 255);
        border-radius: 10px;
    }

    .navbar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
    }
}