.input-ctn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.input-box {
    height: 2rem;
    width: 20rem;
}

.search-btn {
    border-radius: 5px;
    border-width: 1px;
    height: 2.3rem;
}

.lupa-img {
    height: 1.5rem;
}

.not-found {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5rem 0;
}
