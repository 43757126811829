.naprodaj-title {
    width: max-content;
    margin: auto;
    padding: 4rem 0 1rem 0;
}

.naprodaj-txt {
    display: flex;
    justify-content: center;
    padding: 1rem 3rem;
    text-align: justify;
}