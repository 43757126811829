.link-ctn {
    display: flex;
    justify-content: space-evenly;
    margin: 2rem auto;
    padding: 1rem;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    width: min-content;
}

.link-ctn a {
    text-decoration: none;
    color: rgb(0, 0, 0);
    padding: 0.5rem;
    margin: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    border: solid 2px transparent;
    transition: background-color 0.2s,
    border-color 0.2s;
}

.link-ctn a:hover {
    background-color: rgb(232, 218, 218);
    border-color: black;
    transition-duration: 1.5s;
}

.link-ctn a:focus {
    background-color: rgb(232, 218, 218);
    border-color: black;
}

.link-icon {
    font-size: xx-large;
    padding-bottom: 0.5rem;
}

.link-ctn p {
    margin: 0;
}

@media only screen and (max-width: 800px) {
    .link-ctn {
        padding: 1rem 0;
    }

    .link-ctn a {
        margin: 0 0.2rem;
    }
}

/* @media only screen and (max-width: 400px) {
    .link-ctn {
        padding: 0;
    }

} */

@media only screen and (max-width: 530px) {
    .link-ctn {
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
        border-width: 1px;
        width: min-content;
    }

    .last-link {
        grid-column: 3;
    }
}
