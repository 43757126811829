:root {
  --main-font: 'Roboto Slab',serif;
}

html, body {
  height: 100%;
}

body {
  background-color: #ededed;
  margin: 0;
  font-family: var(--main-font);
  display: block;
}
