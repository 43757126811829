.contact {
    background-color: rgb(21, 20, 20);
    color: white;
    padding: 2rem;
    margin-top: 10%;
}

.social {
    display: flex;
    justify-content: flex-start;
    gap: 5vw;
    font-size: 2rem;
    align-items: center;
}

.social a {
    color: white;
}

/* underline transition */
.contact-icons::before {
    content: "";
    display: block;
    position: relative;
    width: 0;
    background-color: white;
    height: 2px;
    transition: 0.5s ease-in-out;
    left: 50%;
    transform: translateX(-50%);
    top: 40px;
}

.contact-icons:hover::before {
    width: 100%;
}

.contact-icons:hover {
    cursor: pointer;
}

.email {
    display: flex;
    align-items: center;
    font-size: medium;
    padding-left: 8px;
}


@media only screen and (min-width: 650px) {
    .contact {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin: 0;
    }
}