.blog-content {
    padding-top: 4rem;
    padding-bottom: 4rem;
    font-family: 'IM Fell English', serif;
}

.blog-intro {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding-top: 3rem;
}

.blog-intro-img {
    justify-self: center;
    width: 100px;
}

.blog-main-title {
    margin: 0;
    font-size: 40px;
    align-self: center;
    font-weight: lighter;
    font-family: 'IM Fell English SC', serif;
}

.blog-descr {
    text-align: justify;
    grid-column: 1 / -1;
    width: 80%;
    justify-self: center;
}

@media only screen and (min-width: 600px) {
    .blog-intro {
        margin-bottom: 5rem;
    }

    .blog-intro-img {
        width: 40%;
        justify-self: right;
        padding-right: 20%;
        max-width: 200px;
        min-width: 120px;
    }

    .blog-main-title,
    .blog-descr {
        grid-row: 1;
        grid-column: 2;
    }

    .blog-main-title {
        align-self: start;
    }

    .blog-descr {
        margin-top: 4rem;
        width: 80%;
        max-width: 500px;
        justify-self: left;
    }
}

@media only screen and (min-width: 800px) {
    .blog-content{
        background: linear-gradient(to right, #A49E9E 40px, #ededed 40px 100%);
    }

}

@media only screen and (min-width:1400px) {
    .blog-link-elements {
        margin: 0 10%;
    }
}
