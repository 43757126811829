.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home p {
    font-size: large;
}

.face-img {
    padding-top: 2rem;
    width: 200px;
}

.image-border {
    border-bottom-style: double;
    padding-bottom: 2rem;

}

.intro-text {
    padding: 2rem 3rem;
    text-align: justify;
    max-width: 800px;
}

.phrase {
    text-align: center;
    display: flex;
    justify-content: center;
    padding-top: 3rem;
}

.phrase h1 {
    padding-bottom: 2rem;
    border-bottom: solid 3px;
    margin: 0 100%;
}

.more-intro {
    text-align: justify;
    padding: 0 3rem;
    padding-top: 5rem;
}

.napolju-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    border-radius: 130px;
    padding: 1rem 0;
}

@media only screen and (min-width: 650px) {
    .intro {
        flex-direction: row;
        padding-top: 3rem;
    }

    .image-border {
        border-bottom: none;
        border-right-style: double;
        padding-right: 3rem;
        padding-bottom: 0;
        padding-top: 0;
        }

    .phrase h1 {
        margin: 0 30%;
    }
}

@media only screen and (min-width: 900px) {
    .intro-text {
        padding: 0 6rem;
    }

    .more-intro {
        display: grid;
        grid-template-columns: 1.5fr 1fr;
        margin-bottom: 10%;
        align-items: center;
    }

    .more-intro p {
        padding: 3rem 5%;
    }

    .napolju-img {
        height: 350px;
        width: auto;
    }
}