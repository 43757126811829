.not-found-txt {
    height: 300px;
    font-size: 80px;
    display: flex;
    justify-content: center;
}

.not-found-img {
    height: 70vh;
    width: auto;
}

.img-ctn {
    text-align: center;
}
