.all-img-container {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    align-items: center;
    gap: 4rem;
    padding: 3rem 2rem;
}

.all-img-container img {
    border-width: 2px;
    filter: grayscale(0);
    transition: filter 1s, transform 1s !important;
    border-color: rgb(179 171 171);
    border-radius: 2%;
    box-shadow: 0px 0px 25px rgb(93 87 87 / 30%);
    border-style: solid;
}

.all-img-container img:hover {
    cursor: pointer;
    filter: grayscale(0.75);
    transform: scaleX(1.1) scaleY(1.1);
}

.img-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 0;
}

.img-text p {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #615f5f;
    text-align: center;
}

.img-descr {
    margin: 0;
}

.top-descr {
    margin-top: 1rem;
}

.img-price {
    font-weight: bold;
    font-size: large;
}

@media only screen and (min-width: 650px) {
    .all-img-container {
        grid-template-columns: 1fr 1fr;
    }

    .all-img-container frame-num {
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 740px) {
    .all-img-container {
        grid-template-columns: 1fr 1fr;
    }

    .frame-num {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 1089px) {
    .frame-num {
        gap: 1rem;
    }
}

@media only screen and (min-width: 1090px) {
    .all-img-container {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .frame-num {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (min-width: 1300px) {
    .all-img-container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .frame-num {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .wide-image-row {
        grid-column: span 2;
    }
}
